import { store } from "@/store/store";


export function WebSocketManager(viewManager) {

	this.viewManager = viewManager;
	this.$rest = null;
	this.url = null;
	this.ws = null;
	this.closed = false;
	this.reconnectTimer = null;
	this.pingCheckTimer = null;
	this.onOpen = null;
	this.lastDate = null;
	this.registerTimeout = null;

	this.onWSMessage = (messageEvent) => {
		// console.log('WS MESSAGE');
		let messageJson = JSON.parse(messageEvent.data);



		if(messageJson.message === 'coordinates') {
			this.viewManager.updateRouteWithNewCoordinates(messageJson.routeId, messageJson.coordinates);
		}
		else if(messageJson.message === 'flyingobject') {
			this.viewManager.flyingObjectManager.updateLocationOfFlyingObject(messageJson.flyingObject);
		}
		if(messageJson.message === 'flyingobjects') {
			this.viewManager.flyingObjectManager.updateLocationOfFlyingObjects(messageJson.flyingObjects);
		}
		else if(messageJson.message === 'ping') {
			this.processPing(messageJson.time);
		}
		else if(messageJson.message === 'demogps') {
			window.feedLocation(messageJson.latitude, messageJson.longitude, messageJson.altitude, messageJson.course, messageJson.speed, messageJson.time);
		}
	};

	this.openWebSocket = async function($rest, onOpen) {
		if(this.ws) {
			return;
		}

		if(!this.$rest) {
			this.$rest = $rest;
		}

		this.onOpen = onOpen;

		if(!this.url) {
			this.url = await this.$rest.getWebSocketUrl();
			if(!this.url) {
				this.doReconnect();
				return;
			}
		}

		return new Promise((resolve) => {
			if(!this.ws) {
				if(typeof WebSocket === 'function' || typeof WebSocket === 'object') {
					this.killReconnectTimer();

					let loggedInEmail = store.getters.loggedInEmail;
					let loggedInUserToken = store.getters.loggedInUserToken;

					let accessToken = null;
					if(loggedInEmail && loggedInUserToken) {
						accessToken = btoa(loggedInEmail + ':' + loggedInUserToken)
					}

					this.ws = new WebSocket(this.url + '?accessToken=' + (accessToken || ""));

					this.ws.onopen = (_event) => {
						this.closed = false;
						console.log('WS OPEN', _event);
						if(typeof this.onOpen === 'function') {
							this.onOpen();
						}
						this.lastDate = null;
						this.checkPing();
						resolve(true);
					};


					this.ws.onmessage = this.onWSMessage;

					this.ws.onclose = (_event) => {
						console.log('WS CLOSE', _event);
						if(!this.closed) {
							this.closed = true;
							this.doReconnect();
						}
						this.killPingCheckTimer();
						resolve(false);
					};
				}
				else {
					alert('Error: Your browser does not support web sockets! Live update is not available.');
					resolve(false);
				}
			}
			else {
				resolve(false);
			}
		});
	};

	this.closeWebSocket = function() {
		if(this.ws && !this.closed) {
			this.killReconnectTimer();
			this.closed = true;
			this.ws.close();
			this.ws = null;
		}
	};

	this.tempCloseWebSocket = function() {
		if(this.ws && !this.closed) {
			this.ws.close();
			this.ws = null;
		}
	};

	this.doReconnect = function() {
		this.closeWebSocket();
		this.ws = null;
		this.closed = true;
		this.killReconnectTimer();
		this.reconnectTimer = setTimeout(async () => {
			if(!await this.openWebSocket(this.$rest, this.onOpen)) {
				this.reconnectTimer = null;
				this.doReconnect();
			}
		}, 5000);
	};

	this.killReconnectTimer = function() {
		if(this.reconnectTimer) {
			clearTimeout(this.reconnectTimer);
			this.reconnectTimer = null;
		}
	};

	this.killPingCheckTimer = function() {
		if(this.pingCheckTimer) {
			clearTimeout(this.pingCheckTimer);
			this.pingCheckTimer = null;
		}
	};

	this.checkPing = function() {
		this.killPingCheckTimer();
		this.pingCheckTimer = setTimeout(() => {
			this.pingCheckTimer = null;
			if(this.lastDate != null) {
				let diff = new Date().getTime() - this.lastDate;
				//console.log("WS DIFF", diff);
				if(diff >= 20000 && diff < 1800000) {
					this.lastDate = null;
					this.tempCloseWebSocket();
					return;
				}
			}
			this.checkPing();
		}, 5000);
	};

	this.processPing = function(time) {
		if(time) {
			try {
				this.lastDate = new Date();
			}
			catch(err) {
			}
		}
	};

	this.registerToRouteUpdates = function(routeId) {
		routeId = Number(String(routeId || "").split("-")[0])
		if(this.ws) {
			this.ws.send(JSON.stringify({
				command: 'registerToRouteUpdates',
				routeId: routeId
			}));
		}
	};

	this.unregisterFromRouteUpdates = function(routeId) {
		routeId = Number(String(routeId || "").split("-")[0])
		if(this.ws) {
			this.ws.send(JSON.stringify({
				command: 'unregisterFromRouteUpdates',
				routeId: routeId
			}));
		}
	};

	/**
	 * @param {{
	 *   registrationNumber: string|undefined,
	 *   maxAltitude: number|undefined,
	 *   minAltitude: number|undefined,
	 *   maxViewDistance: number|undefined,
	 *   transponderTypes: string|undefined
	 *   onlyThis: boolean|undefined
	 * }} options
	 */
	this.registerToFlyingObjectUpdates = function({
		registrationNumber,
		maxAltitude,
		minAltitude,
		maxViewDistance,
		transponderTypes,
		onlyThis,
		countries
	}) {

		if(this.registerTimeout) {
			clearTimeout(this.registerTimeout);
			this.reconnectTimer = null;
		}
		if(!this.ws) return;

		this.registerTimeout = setTimeout(() => {
			this.ws.send(JSON.stringify({
				command: 'registerToFlyingObjectUpdatesV3',
				maxAltitude: maxAltitude,
				minAltitude: minAltitude,
				registrationNumber: registrationNumber,
				maxViewDistance: maxViewDistance,
				transponderTypes: transponderTypes,
				onlyThis: onlyThis,
				countries: countries

			}));
		}, 200);
	};

	this.unregisterFromFlyingObjectUpdates = function() {
		if(this.ws) {
			this.ws.send(JSON.stringify({
				command: 'unregisterFromFlyingObjectUpdates'
			}));
		}
	}

	this.registerToDemoGPS = function(registrationNumber) {
		if(this.ws) {
			this.ws.send(JSON.stringify({
				command: 'registerToDemoGPS',
				registrationNumber: registrationNumber
			}));
		}
	};

}
